<template>
  <!-- TILES SECTION -->
  <div class="row">
    <div class="col-3">
      <div class="white-box text-end">
        <div class="white-box-top"></div>
        <div class="line-cart">
          <LineChart :chartData="salesByDays" :options="optionsNoText" />
        </div>
        <div class="text-area right">
          <h2 class="bold-19 text-default mb-2">Orders</h2>
          <h2 class="bold-24 text-default">
            {{ total?.success?.orders }}
          </h2>
        </div>
      </div>
    </div>
    <div class="col-3 row mb-0 pb-0">
      <div class="col-6">
        <div class="white-box text-center">
          <div class="white-box-top"></div>
          <div class="text-area">
            <h2 class="bold-19 text-danger mb-2">Returns</h2>
            <h2 class="bold-24 text-danger">
              {{ total?.success?.return }}
            </h2>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="white-box text-center">
          <div class="white-box-top"></div>
          <div class="text-area">
            <h2 class="bold-19 text-primary mb-2">Changes</h2>
            <h2 class="bold-24 text-primary">
              {{ total?.success?.changes }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="white-box text-end">
        <div class="white-box-top"></div>
        <div class="line-cart line-cart-10">
          <LineChart :chartData="avgByDays" :options="optionsNoText" />
        </div>
        <div class="text-area right">
          <h2 class="bold-19 text-green mb-2">Avg basket</h2>
          <h2 class="bold-19 text-green">
            <EditOnClick
              :model-value="total?.success?.avg"
              type="price"
              :padding="false"
              :editable="false"
            />
          </h2>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="white-box">
        <div class="white-box-top"></div>
        <div class="text-area">
          <div
            class="row mb-2 ms-1 ps-3 pe-4"
            :style="`background: linear-gradient(
                        90deg,
                        rgba(180, 96, 220, 0.1) 0%,
                        rgba(180, 96, 220, 0.1) ${percent(
                          total?.success?.orders / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) ${percent(
                          total?.success?.orders / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) 100%
                      );`"
          >
            <div class="col-8 pt-1 bold-14 text-gradient">Successful</div>
            <div class="col bold-19 text-gradient text-end">
              {{ percent(total?.success?.orders / totalCount) }}%
            </div>
          </div>
          <div
            class="row mb-2 ms-1 ps-3 pe-4"
            :style="`background: linear-gradient(
                        90deg,
                        rgba(180, 96, 220, 0.1) 0%,
                        rgba(180, 96, 220, 0.1) ${percent(
                          total?.failed?.count / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) ${percent(
                          total?.failed?.count / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) 100%
                      );`"
          >
            <div class="col-8 pt-1 bold-14 text-gradient">Failed</div>
            <div class="col bold-19 text-gradient text-end">
              {{ percent(total?.failed?.count / totalCount) }}%
            </div>
          </div>
          <div
            class="row mb-2 ms-1 ps-3 pe-4"
            :style="`background: linear-gradient(
                        90deg,
                        rgba(180, 96, 220, 0.1) 0%,
                        rgba(180, 96, 220, 0.1) ${percent(
                          total?.not_finished?.count / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) ${percent(
                          total?.not_finished?.count / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) 100%
                      );`"
          >
            <div class="col-8 pt-1 bold-14 text-gradient">Not finished</div>
            <div class="col bold-19 text-gradient text-end">
              {{ percent(total?.not_finished?.count / totalCount) }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="white-box">
        <div class="white-box-top"></div>
        <div class="mt-3">
          <div id="funnel" style="width: 100%; height: 230px"></div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">Sales by medium</h2>
          <div class="mb-4">
            <BarChart
              :chartData="salesByMediums"
              :options="{
                indexAxis: 'y',
                padding: 0,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                },
              }"
            />
          </div>
          <div>
            <div class="row" v-for="(row, i) in data.mediums" :key="row.id">
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ row.name }}
              </div>
              <div class="col-4">
                {{ row.count }}
              </div>
            </div>
          </div>
          <div class="regular-10 mt-2">
            <p>
              <b>Something not feel right?</b> Check the utm_medium parameter in
              your ads, newsletter and other promotions.
            </p>
            <p>
              <b>utm_medium tips:</b> paid, social, referral, email, cpc,
              organic
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">Sales by sources</h2>
          <div class="mb-4">
            <BarChart
              :chartData="salesBySources"
              :options="{
                indexAxis: 'y',
                padding: 0,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                },
              }"
            />
          </div>
          <div>
            <div class="row" v-for="(row, i) in data.sources" :key="row.id">
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ row.name }}
              </div>
              <div class="col-4">
                {{ row.count }}
              </div>
            </div>
          </div>
          <div class="regular-10 mt-2">
            <p>
              <b>Something not feel right?</b> Check the utm_source parameter in
              your ads, newsletter and other promotions.
            </p>
            <p>
              <b>utm_source tips:</b> ig, fb, tiktok, twitter, newsletter,
              influencer, etc.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">Sales by referrers</h2>
          <div class="mb-4">
            <BarChart
              :chartData="salesByReferrers"
              :options="{
                indexAxis: 'y',
                padding: 0,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                },
              }"
            />
          </div>
          <div>
            <div class="row" v-for="(row, i) in data.referrers" :key="row.id">
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ row.name }}
              </div>
              <div class="col-4">
                {{ row.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">Sales by campaigns</h2>
          <div class="mb-4">
            <BarChart
              :chartData="salesByCampaigns"
              :options="{
                indexAxis: 'y',
                padding: 0,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                },
              }"
            />
          </div>
          <div>
            <div class="row" v-for="(row, i) in data.campaigns" :key="row.id">
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ row.name }}
              </div>
              <div class="col-4">
                {{ row.count }}
              </div>
            </div>
          </div>
          <div class="regular-10 mt-2">
            <p>
              <b>Something not feel right?</b> Check the utm_campaign parameter
              in your ads, newsletter and other promotions.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="row px-2">
          <div class="col-3">
            <h2 class="bold-19 mb-3">Sales by countries</h2>
            <RadarChart
              :chartData="byContinents"
              :options="{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }"
            />
          </div>
          <div class="col-9 height-420 scroll-y">
            <OneByOneTable
              v-model:rows="countryRows"
              v-model:items="data.countries"
              :show-add="false"
              :show-edit="false"
              :show-del="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">Sales by channels</h2>
          <div class="col-9 mb-4">
            <PieChart
              :chartData="salesByChannel"
              :options="{
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                },
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-8 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="row px-2">
          <div class="col-5">
            <h2 class="bold-19 mb-3">Sales by products</h2>
            <BarChart
              :chartData="byProducts"
              :options="{
                indexAxis: 'y',
                padding: 0,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                },
              }"
            />
          </div>
          <div class="col-7 height-420 scroll-y">
            <OneByOneTable
              v-model:rows="productsRows"
              v-model:items="data.products"
              :show-add="false"
              :show-edit="false"
              :show-del="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-7 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <div class="col-5">
            <h2 class="bold-19">Sales by categories</h2>
            <div class="mt-2 mb-4">
              <RadarChart
                :chartData="byCategories"
                :options="{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }"
              />
            </div>
          </div>
          <div class="col-7">
            <div class="row" v-for="(row, i) in data.categories" :key="row.id">
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-5">
                {{ row.name }}
              </div>
              <div class="col-4">
                <EditOnClick
                  v-model="row.revenue"
                  type="price"
                  :editabl="false"
                />
              </div>
              <div class="col-2">
                {{ row.orders }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="white-box-big text-center mt-3">
        <div class="white-box-top"></div>
        <div class="text-area">
          <div class="line-cart">
            <LineChart :chartData="revenueByDays" :options="optionsNoText" />
          </div>
          <h2 class="bold-19 text-gradient mb-2 mt-3">Revenue</h2>
          <div class="bold-24 text-gradient">
            <EditOnClick
              :model-value="total?.success?.revenue"
              type="price"
              :editable="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">Visitors by platforms</h2>
          <div class="mt-2 mb-4">
            <RadarChart
              :chartData="byPlatforms"
              :options="{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }"
            />
          </div>
          <div>
            <div class="row" v-for="(row, i) in data.platforms" :key="row.id">
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ row.name }}
              </div>
              <div class="col-4">
                {{ row.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">Visitors by device types</h2>
          <div class="mt-2 mb-4">
            <RadarChart
              :chartData="byDeviceTypes"
              :options="{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }"
            />
          </div>
          <div>
            <div
              class="row"
              v-for="(row, i) in data.device_types"
              :key="row.id"
            >
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ row.name }}
              </div>
              <div class="col-4">
                {{ row.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">Visitors by browsers</h2>
          <div class="col-10 mt-2 mb-4">
            <PieChart
              :chartData="byBrowsers"
              :options="{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }"
            />
          </div>
          <div>
            <div class="row" v-for="(row, i) in data.browsers" :key="row.id">
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ row.name }}
              </div>
              <div class="col-4">
                {{ row.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 mt-3">
      <div class="white-box h-100">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">Visitors by device manufacturers</h2>
          <div class="mb-4">
            <BarChart
              :chartData="byManufacturers"
              :options="{
                padding: 0,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    display: true,
                    grid: {
                      display: false,
                    },
                  },
                },
              }"
            />
          </div>
          <div>
            <div
              class="row"
              v-for="(row, i) in data.manufacturers"
              :key="row.id"
            >
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ row.name }}
              </div>
              <div class="col-4">
                {{ row.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";
import { useStore } from "vuex";
import date from "../../modules/date";
import FunnelGraph from "funnel-graph-js/dist/js/funnel-graph";
import "funnel-graph-js/dist/css/main.min.css";
import "funnel-graph-js/dist/css/theme.min.css";
import { PieChart, RadarChart, BarChart, LineChart } from "vue-chart-3";
import OneByOneTable from "../../components/lists/OneByOneTable.vue";
import { defineAsyncComponent } from "vue";
import EditOnClick from "../../components/inputs/EditOnClick.vue";

export default {
  name: "WebAnalytics",
  components: {
    LineChart,
    EditOnClick,
    OneByOneTable,
    PieChart,
    RadarChart,
    BarChart,
  },
  data() {
    return {
      store: useStore(),
      data: {},
      total: {},
      colors: [
        "#4CC9F0",
        "#F9DB6D",
        "#464D77",
        "#F71735",
        "#04A777",
        "#b585dc",
        "#3A0CA3",
        "#F79824",
        "#4F5165",
        "#6FAE90",
        "#7D2E68",
        "#502419",
        "#645DD7",
        "#C7CB85",
        "#C7FFED",
        "#9DB5B2",
        "#55505C",
      ],
      productsRows: [
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "Serial",
          key: "name",
          type: "text",
          show: true,
          editable: false,
        },
        {
          name: "Orders",
          key: "orders",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Revenue",
          key: "revenue",
          type: "price",
          show: true,
          editable: false,
        },
        {
          name: "Views",
          key: "views",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Add to cart",
          key: "add_to_cart",
          type: "number",
          show: true,
          editable: false,
        },
      ],
      countryRows: [
        {
          name: "Country",
          key: "country",
          type: "string",
          show: true,
          textLeft: true,
          editable: false,
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Country")
          ),
        },
        {
          name: "Orders",
          key: "orders",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Web orders",
          key: "web_orders",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Shop orders",
          key: "shop_orders",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Add to cart",
          key: "add_to_cart",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "View content",
          key: "view_content",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Sessions",
          key: "sessions",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Abandonment carts",
          key: "abandonment_cart_percent",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Revenue",
          key: "revenue",
          type: "price",
          show: true,
          editable: false,
        },
        {
          name: "Revenue from ads",
          key: "revenue_from_ads",
          type: "price",
          show: true,
          editable: false,
        },
        {
          name: "RPM",
          key: "rpm",
          type: "price",
          show: true,
          editable: false,
        },
        {
          name: "Avg basket",
          key: "avg_basket",
          type: "price",
          show: true,
          editable: false,
        },
        {
          name: "PCR",
          key: "pcr",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Conversion rate",
          key: "conversion_rate",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Abandonment carts",
          key: "abandonment_cart_percent",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Refuses",
          key: "refuses",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Refuses percent",
          key: "refuses_percent",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Denies",
          key: "denies",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Denies percent",
          key: "denies_percent",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Returns",
          key: "returns",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Returns percent",
          key: "returns_percent",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Changes",
          key: "changes",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Changes percent",
          key: "changes_percent",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Avg item quantity",
          key: "avg_quantity",
          type: "number",
          show: true,
          editable: false,
        },
      ],
      graph: new FunnelGraph({
        container: "#funnel",
        gradientDirection: "horizontal",
        height: 230,
        width: window.innerWidth - 450,
        data: {
          labels: [
            "Sessions",
            "Product view",
            "Add To Cart",
            "Initiate checkout",
            "Purchase",
          ],
          colors: ["#B460DC", "#713AE8"],
          values: [0, 0, 0, 0, 0],
        },
        displayPercent: true,
        direction: "horizontal",
      }),
      salesByChannel: {
        labels: [],
        datasets: [
          {
            label: "Sales by channels",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      salesByReferrers: {
        labels: [],
        datasets: [
          {
            label: "Sales by referrers",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      salesByMediums: {
        labels: [],
        datasets: [
          {
            label: "Sales by mediums",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      salesBySources: {
        labels: [],
        datasets: [
          {
            label: "Sales by sources",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      salesByCampaigns: {
        labels: [],
        datasets: [
          {
            label: "Sales by campaigns",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      byPlatforms: {
        labels: [],
        datasets: [
          {
            label: "Visitors by platforms",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      byDeviceTypes: {
        labels: [],
        datasets: [
          {
            label: "Visitors by device types",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      byManufacturers: {
        labels: [],
        datasets: [
          {
            label: "Visitors by device manufacturers",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      byBrowsers: {
        labels: [],
        datasets: [
          {
            label: "Visitors by browsers",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      byContinents: {
        labels: [],
        datasets: [
          {
            label: "Sales by regions",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      byProducts: {
        labels: [],
        datasets: [
          {
            label: "Top selling products",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      byCategories: {
        labels: [],
        datasets: [
          {
            label: "Top selling categories",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      optionsNoText: {
        padding: 0,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      },
      options: {
        padding: 0,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      },
      revenueByDays: {
        labels: [],
        datasets: [
          {
            label: "Sold Products",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
      salesByDays: {
        labels: [],
        datasets: [
          {
            label: "Sales",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
      avgByDays: {
        labels: [],
        datasets: [
          {
            label: "Sales",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
    };
  },
  computed: {
    date() {
      return this.store.state.topBar.date;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  watch: {
    date: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  mounted() {
    this.graph.draw();
    this.loadData();
  },
  methods: {
    formatDate(val) {
      return date.format(val, false, false);
    },
    percent(val) {
      return Math.round(val * 10000) / 100;
    },
    loadData() {
      http
        .fetch(
          `/home?1${
            (this.date[0]
              ? "&from=" + this.date[0].toISOString().split("T")[0]
              : "") +
            (this.date[1]
              ? "&to=" + this.date[1].toISOString().split("T")[0]
              : "")
          }`
        )
        .then((data) => {
          this.total = data.total;
          this.appData = data.apps;
          this.payments = data.payments;

          let labels = [];
          let revenue = [];
          let sales = [];
          let avg = [];
          for (const date in data.total.daily) {
            labels.push(date);
            revenue.push(Number.parseFloat(data.total.daily[date].total));
            sales.push(Number.parseFloat(data.total.daily[date].count));
            avg.push(Number.parseFloat(data.total.daily[date].avg));
          }
          this.revenueByDays.labels = labels;
          this.revenueByDays.datasets[0].data = revenue;
          this.salesByDays.labels = labels;
          this.salesByDays.datasets[0].data = sales;
          this.avgByDays.labels = labels;
          this.avgByDays.datasets[0].data = avg;

          if (this.firstLoad) {
            this.firstLoad = false;
          }
        });
      http
        .fetch(
          `/analytics/web?1${this.q ? "&q=" + this.q : ""}${
            (this.date[0]
              ? "&from=" + this.date[0].toISOString().split("T")[0]
              : "") +
            (this.date[1]
              ? "&to=" + this.date[1].toISOString().split("T")[0]
              : "")
          }`
        )
        .then((data) => {
          for (const row of data.products) {
            if (row.image) {
              row.image = this.mediaServer + "/" + row.image;
            }
          }
          this.data = data;
          this.setChart(data.sales_channels, this.salesByChannel);
          this.setChart(data.sources, this.salesBySources);
          this.setChart(data.mediums, this.salesByMediums);
          this.setChart(data.referrers, this.salesByReferrers);
          this.setChart(data.campaigns, this.salesByCampaigns);
          this.setChart(data.browsers, this.byBrowsers);
          this.setChart(data.manufacturers, this.byManufacturers);
          this.setChart(data.device_types, this.byDeviceTypes);
          this.setChart(data.platforms, this.byPlatforms);
          this.setChart(data.regions, this.byContinents);
          this.setChart(data.products, this.byProducts);
          this.setChart(data.categories, this.byCategories);

          // BUG in the library, fix
          let e = document.getElementsByClassName("svg-funnel-js__labels");
          for (const eElement of e) {
            eElement.style.display = "none";
          }
          e = document.getElementsByClassName("svg-funnel-js__container");
          for (const eElement of e) {
            eElement.style.display = "none";
          }

          this.graph.updateData({
            labels: [
              "Sessions",
              "Product view",
              "Add To Cart",
              "Initiate checkout",
              "Purchase",
            ],
            colors: ["#B460DC", "#713AE8"],
            values: [
              data.funnel.sessions,
              data.funnel.view_content,
              data.funnel.add_to_cart,
              data.funnel.initiate_checkout,
              data.funnel.purchase,
            ],
          });

          // BUG in the library, fix
          setTimeout(() => {
            let e = document.getElementsByClassName("svg-funnel-js__labels");
            e[e.length - 1].style.display = "flex";
            e = document.getElementsByClassName("svg-funnel-js__container");
            e[e.length - 1].style.display = "flex";
          }, 300);
        });
    },
    getColor(i) {
      let e = i - this.colors.length * Math.floor(i / this.colors.length);
      return this.colors[e];
    },
    setChart(data, chartset, row = "count") {
      let chart = [];
      let colors = [];
      let labels = [];
      for (const i in data) {
        labels.push(data[i].name);
        chart.push(data[i][row]);
        colors.push(this.getColor(i));
      }

      chartset.labels = labels;
      chartset.datasets[0].data = chart;
      chartset.datasets[0].backgroundColor = colors;
    },
  },
};
</script>
